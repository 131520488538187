<template>
  <div class="container" v-title :data-title="$t('i18n.updateLog')">
    <div id="outer-title">{{ $t("i18n.updateLog") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @handleEdit="handleEdit"
        @handleDelete="handleDelete"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
    <el-dialog
      :title="$t('i18n.view')"
      v-model="drawer"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item
          v-for="(item, index) in timelineData"
          :key="index"
          :title="item.id"
          :name="index"
        >
          <el-timeline>
            <el-timeline-item
              v-for="child in item.child"
              :key="child"
              :type="child.type"
              :timestamp="child.createdDate"
              placement="top"
            >
              <el-card>
                <h4>{{ child.updateContent }}</h4>
                <p>{{ child.createdBy }}提交于{{ child.createdDate }}</p>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </el-collapse-item>
      </el-collapse>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlTable from "../../components/table";
export default {
  name: "UpdateLog",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      // table数据
      drawer: false,
      activeNames: [0],
      tableData: [],
      options: {
        // table 的参数
        isAdd: proxy.$defined.btnPermission("添加日志"), // 是否启用新增功能
        isEdit: proxy.$defined.btnPermission("更新日志"), // 是否启用编辑功能
        isDelete: proxy.$defined.btnPermission("删除日志"), // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: false, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "updateContent",
          label: "content",
          align: "left",
          search: true,
          width: 600,
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "createdBy",
          label: "lastModifier",
          align: "center",
          row: false,
        },
        {
          prop: "createdDate",
          label: "lastModifyDate",
          align: "center",
          row: false,
        },
      ],
      operates: {
        // 列操作按钮
        width: 140,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "view",
            class: "primary",
            icon: "el-icon-view",
            show: true,
            type: "outer",
            method: () => {
              state.drawer = true;
            },
          },
        ],
      },
      loading: true,
      editData: {},
      timelineData: [],
      formInline: {
        content: "",
      },
    });

    const init = async (params) => {
      state.loading = true;
      params.content = params.updateContent;
      const { data } = await proxy.$api.common.logData(params);
      state.tableData = data;
      timeLineFunc(data);
      state.loading = false;
    };

    const timeLineFunc = (data) => {
      var arr = [],
        obj = {};
      data.map((item, index) => {
        item.type = index == 0 ? "success" : "primary";
        let year = item.createdDate.split("-")[0];
        if (arr.indexOf(year) == -1) {
          arr.push(year);
          obj[year] = [];
        }
        obj[year].push(item);
      });
      let timelineData = [];
      for (var key in obj) {
        var obj1 = {};
        obj1.id = key;
        obj1.child = obj[key];
        timelineData.push(obj1);
      }
      state.timelineData = timelineData.reverse();
    };

    const handleEdit = async (val, pages) => {
      // 新增/编辑数据
      let msg = val.id ? "edit" : "new";
      val.content = val.updateContent;
      val.id
        ? await proxy.$api.common.editLogData(val.id, val)
        : await proxy.$api.common.addLogData(val);
      proxy.$defined.tip(t("i18n." + msg) + t("i18n.success"), "success");
      // 初始化 刷新
      init(pages);
    };

    const handleDelete = (row, pages) => {
      proxy.$api.common.delLogData(row.id).then(() => {
        init(pages);
        proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
      });
    };

    return {
      ...toRefs(state),
      handleEdit,
      init,
      handleDelete,
    };
  },
};
</script>
